import React from 'react';
import Home from './pages/Home/'
import MobileOverlay from './pages/MobileOverlay/'

function App() {
  return (
    <>
      <Home />
      {/* <MobileOverlay /> */}
    </>
  );
}

export default App;
